import './fonts/AeonikTRIAL-Bold.otf';
import './fonts/AeonikTRIAL-Regular.otf';
import './App.css';
import Homepage from './pages/homepage';

function App() {
  return (
    <div className="App">
    <Homepage />
    </div>
  );
}

export default App;
